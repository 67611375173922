<template>
  <div class="release">
    <van-tabs v-model:active="active" @click="onChange" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs">
      <van-tab title="电子书" name="0">
        <NoData v-if="communityList && communityList.length == 0" :bottomBg="true" ></NoData>
        <div class="reward-list">
          <div class="reward-list-top">

          </div>

          <van-list
            v-model:loading="bookloading"
            :finished="bookfinished"
            :finished-text="communityList.length > 5 ? '没有更多了':''"
            @load="bookonLoad"
          >
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item :title="item.title" :name="index" v-for="(item,index) in communityList" :key="index">
                <div class="show-content-file" v-for="(citem,cindex) in item.resources" :key="cindex" >
                  <img class="show-content-file-l" :src="citem.url" @click="download(citem.file,citem.name)" />
                  <div class="show-content-file-r  white-space" @click="download(citem.file,citem.name)" >
                    <div class="show-content-file-r-t white-space">{{citem.name}}</div>
                    <div class="show-content-file-r-t">{{(citem.size / 1024 / 1024).toFixed(2) > 0.01 ? (citem.size / 1024 / 1024).toFixed(2):0.01}}MB</div>
                  </div>
                  <div class="show-content-file-b copy-btn" :data-clipboard-text="citem.file" @click="copy">复制下载链接</div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </van-list>
        </div>

      </van-tab>
      <van-tab title="软件包" name="1">
        <NoData v-if="packageList && packageList.length == 0" :bottomBg="true" ></NoData>
        <div class="reward-list">
          <div class="reward-list-top">

          </div>

          <van-list
            v-model:loading="packageloading"
            :finished="packagefinished"
            :finished-text="packageList.length > 5 ? '没有更多了':''"
            @load="packageonLoad"
          >
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item :title="item.title" :name="index" v-for="(item,index) in packageList" :key="index">
                <div class="show-content-file" v-for="(citem,cindex) in item.resources" :key="cindex" >
                  <img class="show-content-file-l" :src="citem.url" @click="download(citem.file,citem.name)" />
                  <div class="show-content-file-r  white-space" @click="download(citem.file,citem.name)">
                    <div class="show-content-file-r-t white-space">{{citem.name}}</div>
                    <div class="show-content-file-r-t">{{(citem.size / 1024 / 1024).toFixed(2) > 0.01 ? (citem.size / 1024 / 1024).toFixed(2):0.01}}MB</div>
                  </div>
                  <div class="show-content-file-b copy-btn" :data-clipboard-text="citem.file" @click="copy">复制下载链接</div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>

</template>

<script>
import UserInfo from "components/userInfo";
import NoData from "components/NoData";
import Clipboard from 'clipboard'
export default {
  name: "user.release",
  data(){
    return{
      active:0,
      page:1,
      communityList:[],
      load:true,
      packageList:[],
      packageload:true,
      packagepage:1,
      bookloading:false,
      bookfinished:true,
      packageloading:false,
      packagefinished:true,
      activeName:0,

    }
  },

  created() {
    // if (this.$store.getters.acts) {
    //   this.active = this.$store.getters.acts
    //   this.$store.commit('M_ACTS','0')
    // }
    this.listupdata()
    if (this.$route.query.act) {
      if (this.$route.query.act == 'book'){
        this.active = '0'
      }else {
        this.active = '1'
      }
    }
  },
  methods:{
    // 点击复制事件
    copy(){
      var clipboard = new Clipboard('.copy-btn');
      clipboard.on('success', e => {
        this.$toast('下载链接已复制');
        // 释放内存
        clipboard.destroy()
      });
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy()
      })
    },
    download(file,name){
      //下载文件
      let url = file
      let filename = name
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    //电子书列表
    getcommunityList(){
      let url = this.$api.communityDownloadMine + '?pageSize=5&type=book&page=' + this.page
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.communityList = this.communityList.concat(res.data.data)
            this.page = this.page + 1
            this.bookloading = false;
            this.bookfinished = false
          }else{
            this.load = false
            this.bookfinished = true
          }
        }
      })
    },
    //软件包列表
    getpackageList(){
      let url = this.$api.communityDownloadMine + '?pageSize=5&type=package&page=' + this.packagepage
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.packageList = this.packageList.concat(res.data.data)
            this.packagepage = this.packagepage + 1
            this.packageloading = false
            this.packagefinished = false

          }else{
            this.packageload = false
            this.packagefinished = true
          }
        }
      })
    },
    listupdata(){
      this.page = 1
      this.communityList = []
      this.load = true
      this.packageList = []
      this.packageload = true
      this.packagepage = 1
      this.getcommunityList()
      this.getpackageList()
    },
    onChange(event) {
      this.active = event
    },
    bookonLoad(){
      if(this.load){
        this.getcommunityList()
      }
    },
    packageonLoad(){
      if(this.packageload){
        this.getpackageList()
      }
    },
    updata(){
      this.listupdata()
    }
  },
  components:{
    UserInfo,
    NoData,
    Clipboard
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/release/release.scss";
.release .show-content-file{
  height: 120/$r;
  background: #F7F7F7;
  border-radius: 6/$r;
  display: flex;
  padding: 25/$r 20/$r;
  box-sizing: border-box;
  margin-bottom: 20/$r;
}
.release .show-content-file .show-content-file-l{
  width: 70/$r;
  height: 70/$r;
  margin-right: 16/$r;
}
.release .show-content-file .show-content-file-r{
  flex: 1;
  font-size: 26/$r;
  font-weight: 400;
  color: #848484;
}
.release .show-content-file .show-content-file-b{
  width: 170/$r;
  line-height: 70/$r;
}
.release .reward-list{
  margin-top: 80/$r;
}
.release .nodata{
  margin-top: 96/$r;
}
</style>
<style lang="scss">
$r: 750/16/1rem;
.release{
  .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 84/$r;
    z-index: 99;
  }
  .van-tabs__line {
    width: 0.8533333333rem !important;
    height: 0.128rem;
    background: #FF6B29;
    border-radius: 0.064rem;
  }
}
</style>