<template>
    <div class="nodata" :class="bottomBg ? 'bottomBg' : ''">
       <img src="~assets/images/index/qx.png" alt="" class="nodataimg" v-if="type == 'right'">
      <img src="~assets/images/index/nodata.png" alt="" class="nodataimg" v-else>
      <div class="no-data-con" :class="bottomBg ? 'bottomBg' : ''">{{ type == 'right' ? '抱歉，无查看其他回答权限':'暂无数据'}}</div>
    </div>
</template>

<script>
export default {
name: "NoData",
    props: ['bottomBg','type']
}
</script>

<style scoped lang="scss">
$r: 750/16/1rem;
.no-data-con{
    height: 100/$r;
    background: #F7F7F7;
    font-weight: 300;
    color: #969799;
    text-align: center;
    font-size: 14px;

}
.nodata{
  width: 100%;
  padding-top: 100/$r;
  text-align: center;
   background: #F7F7F7;
}
.nodataimg{
width: 210/$r;
    height: 115/$r;
    margin-bottom: 20/$r;
  background-size: cover;
}
.bottomBg{
    background: #ffffff;
}
</style>